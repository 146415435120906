@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@import '../node_modules/antd/dist/antd.dark.css';
// @import '/assets/static/OpenSans';

@font-face {
  font-family: 'Open Sans';
  src: url('../public/Fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  // font-weight: bold;
}

* {
  @warn 'Desktop font fam';
  font-family: 'Open Sans' !important;
}
@warn 'in b/w desktop and mobile';
@debug 'in b/w desktop and mobile';

@media only screen and (max-width: 600px) {
  @warn 'Mobile font fam';
  * {
    font-family: 'Open Sans' !important;
  }
}

.ant-btn,
.ant-btn-lg {
  border-radius: 12px;
  border: none;
}

.ant-btn:hover:not(.ant-btn-dangerous, .ant-btn-primary) {
  background-color: unset;
  color: #86bc25;
  border: none;
}

.ant-btn:focus {
  background-color: unset;
  color: #86bc25;
  border: none;
}

.ant-form-item:hover {
  color: #86bc25 !important;
  border-color: #86bc25;
}

.ant-form-item:active {
  color: #86bc25;
  border-color: #86bc25;
}

.ant-form-item:focus {
  color: #86bc25;
  border-color: #86bc25;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #86bc25;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #86bc25;
}

.ant-slider-dot-active {
  border-color: #86bc25;
}

.ant-slider-track {
  background-color: #86bc25;
}

.ant-slider:hover .ant-slider-track {
  background-color: #86bc25;
}

.ant-btn-primary {
  background: #86bc25;
  border-color: #86bc25;
  border-radius: 50px;
  color: black;
}

.ant-btn-primary:hover {
  background: #86bc25;
  border-color: #86bc25;
}

.ant-menu-item-selected {
  color: #fff;
}

.ant-radio-group {
  width: 100%;
}

.ant-menu-item-group-title > div > div {
  color: #8f8f8f;
}

.ant-pagination-item:hover {
  // border-color when pagination is hovered
  border-color: #86bc25;
}
.ant-pagination-item:hover a {
  // inner number color when pagination is hovered
  color: #86bc25;
}
.ant-pagination-item-active {
  // border color when a pagination item is selected
  border-color: #86bc25;
}
.ant-pagination-item-active a {
  // inner number color when pagination item is selected
  color: #86bc25;
}

.ant-pagination-next:hover button {
  color: #86bc25 !important;
  border-color: #86bc25 !important;
}
.ant-pagination-prev:hover button {
  color: #86bc25 !important;
  border-color: #86bc25 !important;
}

.ant-pagination-item:focus-visible a {
  color: #86bc25;
}
.start-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  text-align: center;

  div > .form-start-image {
    align-self: center;
  }

  div > h1 {
    margin-top: 5vh;
    padding: 0 10vw;
  }

  div > p {
    padding: 0 10vw;
    margin-bottom: 5vh;
  }
}

.form-slide {
  display: flex;
  height: 90vh;
  justify-content: center;
  flex-direction: column;
  margin: 2em 4em;
  button {
    margin-top: 1em;
    max-width: 90px;
  }
}

.end-page {
  text-align: center;
  justify-content: center;
  button {
    margin-top: 1em;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7cb305;
  border-right: #7cb305;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: #7cb305;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #fff;
}

.progress-container {
  display: flex;
  justify-content: center;
}

.ant-progress-steps-outer {
  width: 90vw;
}
.ant-progress-steps-item {
  flex: 1 1;
}

.ant-progress-steps-item-active {
  background: #86bc25;
}

.ant-spin-dot-item {
  background: #86bc25 !important;
}

.ant-btn-primary {
  background: #86bc25 !important;
  border-color: #86bc25 !important;
  border-radius: 50px;
  color: black;
  font-weight: 400;
}

.ant-btn-primary:hover {
  background: #86bc25;
  border-color: #86bc25;
  color: black;
}

.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: #86bc25;
}

.ant-slider-handle {
  border-color: #86bc25;
}

.ant-slider-handle.ant-tooltip-open {
  border-color: #86bc25;
}

.ant-slider-dot-active {
  border-color: #86bc25;
}

.ant-slider:hover .ant-slider-track {
  background-color: #a0d93b;
}

.success:hover {
  border-color: #86bc25;
}

.success:focus-within {
  //w/o -within password field focus doesn't turn green
  border-color: #86bc25;
  box-shadow: none;
}

/*
.success:hover{
  border-color: #86bc25;
}
*/

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #86bc25; // selected color
  border-color: #86bc25; // !important
}

.ant-input:focus-within {
  border-color: #86bc25;
  box-shadow: none;
}
.ant-input:hover {
  border-color: #86bc25;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus {
  border-color: #86bc25;
}

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #{lighten($primary-color, 10%)};
}
.ant-select-selector:hover {
  border-color: #86bc25 !important; // !important
}

.ant-select-selector:focus-within {
  border-color: #86bc25 !important; // !important
  box-shadow: none !important;
}

.ant-switch-checked {
  background: #86bc25;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover,
.ant-tab {
  color: #86bc25;
  // font-weight: 500;
}
.ant-tabs-tab-btn:active {
  color: #86bc25;
}
.ant-tabs-tab-active {
  color: #86bc25;
}

.ant-tabs-ink-bar {
  background-color: #86bc25;
}
/*
.ant-select-selection:focus {
  border-color: #86bc25 !important;
}

.ant-select-selector:focus,
.ant-select-selector:focus, .ant-select-selector:active,
.ant-select-open 
*/

.continue-button {
  padding: 10px 30px 35px 30px;
  border-radius: 40px;
  margin: 0 1rem 3rem 1rem;
  display: flex;
  align-self: center;
  width: 80%;
  max-width: 400px;
  justify-content: center;
}

.header-logo {
  position: absolute;
  padding: 3em 0 0 3em;
}

.star-style {
  .ant-rate {
    box-sizing: border-box;
    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    display: inline-block;
    margin: 0;
    width: 55vw;
    // padding-left: 20px;
    // padding-right: 20px;
    // padding-bottom: 8px;
    font-size: calc(6vw);
    line-height: unset;
    // border: 1px solid #86bc25;
    // border-radius: 7px;
  }

  .ant-rate-star {
    // stroke: #86bc25;
    // padding-left: 1vw;
    // padding-right: 1vw;
  }
}

@media screen and (max-width: 900px) {
  .continue-button {
    min-width: 80%;
  }
}

@media screen and (min-width: 901px) {
  .slide {
    padding: 0 20vw;
    justify-content: center;
  }
}
